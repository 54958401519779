import "../App.css";

export default function ConfirmationPage() {
  return (
    <div style={{ background: "#e7edf3" }}>
      <div
        className="container h-100 p-md-5 p-sm-2"
        style={{ marginTop: "70px" }}
      >
        <div className="row card shadow-md border-0 rounded-3 card-body">
          <h1
            className="text-center text-success primary-green mt-2"
            style={{ fontSize: " 6vh" }}
          >
            Thanks for Signing up!
          </h1>
          <p className="content text-center pt-2 fs-3">
            <i className="text-muted">
              Please check your email for your temporary password.
            </i>
            <a
              className="nav-link my-4"
              href={`${process.env.REACT_APP_LOGIN_URL}`}
            >
              <h4 className=" btn btn-success px-5 fs-4">Log In</h4>
            </a>
          </p>
          <div className="container">
            <div className="row justify-content-center mb-3 gy-4">
              <div className="col-sm-12">
                <h2 className="text-center text-success">Want a sneak peak?</h2>
                <h4 className="text-center text-muted">
                  Check out a walkthrough of the platform!
                </h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{
                    height: "100%",
                    minHeight: "300px",
                    maxHeight: "60vh",
                  }}
                >
                  <iframe
                    title="Terra Notes - Quick Walkthrough"
                    src="https://www.loom.com/embed/644a586c70434ba08ae2755b3e013016?sid=d745d639-b8c4-40d6-81ec-c00fdd16a1ef"
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style={{
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div
                  className="shadow-sm rounded overflow-hidden bg-white"
                  style={{
                    height: "100%",
                    minHeight: "300px",
                    maxHeight: "60vh",
                  }}
                >
                  <iframe
                    title="Terra Notes - Full Walkthrough"
                    src="https://www.loom.com/embed/91d8d211164144b98db5a1abeebce302?sid=b1726c3e-240b-4d4c-b0c4-5296edff6e01"
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style={{
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
