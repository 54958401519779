import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ScrollToTop({ allowedAllRoutes = [] }) {
  const { pathname } = useLocation();
  window.history.scrollRestoration = "manual";

  const params = new URLSearchParams(window.location.search);
  const fprValue = params.get("fpr");

  const pathSegments = pathname.split("/").filter(Boolean);

  useEffect(() => {
    // If 'fpr' parameter exists, store it in sessionStorage
    if (fprValue) {
      sessionStorage.setItem("referralId", fprValue);
    } else if (
      pathSegments.length === 2 &&
      allowedAllRoutes.includes(pathSegments[0]?.toLowerCase())
    ) {
      // If the route has two segments and matches allowed routes, store the second part of the path as referralId
      sessionStorage.setItem("referralId", pathSegments[1]);
    } else if (
      pathSegments.length === 1 &&
      !allowedAllRoutes.includes(pathSegments[0]?.toLowerCase())
    ) {
      // If the route has one segment and is not an allowed route, store the first part of the path as referralId
      sessionStorage.setItem("referralId", pathSegments[0]);
    }
  }, [fprValue, pathname, pathSegments]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const body = document.querySelector("body");

    if (body && body.style.overflow === "hidden") {
      body.style.overflow = "auto";
    }
  }, [pathname]);

  return null;
}
